export const faq = [
    {
        question: "Wat is een passende internet snelheid voor mijn huishouden?",
        answer: <div>
                  Voor een goede internetsnelheid adviseren wij een snelheid van 25 Mb/s per persoon binnen je huishouden. 
                  Daarmee zit je altijd goed.<br/> 
                  Een gemiddeld huishouden (met 3 of 4 personen) heeft vaak voldoende aan een snelheid tot 100 Mb/s.
               </div>,
        slug: "wat-is-een-passende-internet-snelheid-voor-mijn-huishouden",
        homepage: 1
     },
     {
        question: "Welke zenders bieden jullie precies aan?",
        answer: <div>Deze zenders kijk je met Digitale TV van Ziggo:<br/>
         <a href="https://www.ziggo.nl/televisie/zenderoverzicht">https://www.ziggo.nl/televisie/zenderoverzicht</a></div>,
        slug: "welke-zenders-bieden-jullie-precies-aan",
        homepage: 1
     },
     {
        question: "Is het ook mogelijk om alleen internet af te nemen?",
        answer: <div>Uiteraard, onze promotie veranderd dan momenteel naar 6 maanden 50% korting op alleen de Internet dienst van Ziggo.<br/>
Er wordt een modem geleverd vanuit Ziggo met 2 wifiboosters.<br/> Zo zorgen we er gezamenlijk voor dat er thuis of op de zaak perfect internet wordt geleverd.
- Bel voor meer informatie of om direct te bestellen 030-2498181.</div>,
        slug: "is-het-ook-mogelijk-om-alleen-internet-af-te-nemen",
        homepage: 1
     },
     {
      question: "Wie is de Smart Sales Company?",
      answer: <div>Wij zijn een full-service gerichte direct sales organisatie die samenwerkt met het grootste telecombedrijf van Nederland. 
      Ons gezamenlijke doel is om een innovatief verschil te maken voor de Nederlandse markt, waarbij eerlijkheid en transparantie voorop staat.<br/><br/>
      
      <strong>Is Smart Sales Company betrouwbaar?</strong><br/>
      Wij zijn een betrouwbare organisatie en zijn trots op onze trustpilot rating waar onze klanten ons kunnen beoordelen. Smart Sales Company staat geregistreerd onder KVK-nummer 75356147.<br/><br/>
      
      <strong>Waarom kies je voor Smart Sales Company</strong><br/>
      Smart Sales Company biedt je een unieke aanbieding. Doordat de aanvraag online wordt verwerkt, kunnen wij jouw aanvraag supersnel behandelen. Daardoor is het mogelijk om al binnen 3 werkdagen te worden aangesloten. Naast de speciale aanbiedingen brengt Smartsalescompany.nl geen kosten in rekening voor het aansluiten en voor het overstappen. Je krijgt ook de mogelijkheid om gebruik te maken van een gratis monteur. Alleen maar goede redenen om te kiezen voor Smart Sales Company!<br/><br/>
      
      <strong>Hoe kan ik Smart Sales Company bereiken?</strong><br/>
      Onze adviseurs zijn van maandag t/m vrijdag van 09:00 – 21:00 uur en zaterdag van 10:00 – 16:00 uur beschikbaar op nummer 030 249 81 81. Je kunt ook een mail sturen naar backoffice@smartsalescompany.nl</div>,
      slug: "wie-is-smartsalescompany",
   },
]